export function ArchetypesWallLantern(props) {
  return (
    <svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' className="hue-icon" {...props}>
      <path
        d='M21.775 24.55c-1.317-.428-3.233-.428-4.55-.001l-1.092-7.759c2.147.273 4.586.273 6.733.001l-1.09 7.759zm3.807-11.794c-1.564-.472-3.598-.713-5.66-.744C19.438 8.619 16.522 6 13 6a6.956 6.956 0 00-4.123 1.356 11.664 11.664 0 00-.344-1.795c-.258-.891-.577-1.396-.907-1.527A.447.447 0 007.454 4H5.547a.453.453 0 00-.17.034c-.345.134-.675.681-.937 1.626-.587 2.135-.587 5.552 0 7.683.262.945.592 1.489.936 1.623a.453.453 0 00.171.034h1.907a.447.447 0 00.172-.034c.33-.131.65-.636.907-1.53.287-.996.435-2.288.458-3.602.94-1.188 2.38-1.968 4.01-1.968 2.501 0 4.584 1.803 5.035 4.176-1.698.087-3.324.321-4.625.714-3.293.994-3.187 2.576.157 3.535.153.044.33.075.491.115l1.442 10.246c.121.449.834.995 2.478 1.237a10.525 10.525 0 003.045 0c1.642-.242 2.32-.791 2.475-1.235l1.441-10.246c.163-.041.343-.073.498-.117 3.34-.959 3.441-2.541.144-3.535z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  )
}
