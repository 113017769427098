export function HeroesBloom(props) {
  return (
    <svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' className="hue-icon" {...props}>
      <path
        d='M25.693 24.557c-2.047-.926-6.448-3.523-10.527-7.604-4.208-4.209-6.882-8.634-7.71-10.626a.502.502 0 01.59-.683c.568.141 1.325.414 2.28.933 5.321 2.881 12.26 9.803 15.153 15.116.509.933.774 1.71.903 2.312a.496.496 0 01-.69.552m1.104-3.582C23.75 15.38 16.645 8.293 11.041 5.257 9.342 4.337 7.996 4 7.025 4c0 0-.616-.01-1.09.181-1.242.536-1.924 2.007-1.924 5.234 0 3.936 1.64 7.773 4.19 10.93l-4.026 6.07a1 1 0 00.828 1.564l6.6-.023a1.998 1.998 0 001.657-.89l1.005-1.508c2.65 1.538 5.55 2.442 8.373 2.442 2.402 0 3.941-.579 4.936-1.589.585-.547.768-2.595-.778-5.436'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  )
}
