export function ArchetypesRecessedCeiling(props) {
  return (
    <svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' className="hue-icon" {...props}>
      <path
        d='M15.993 11c-3.432 0-6.7.616-9.204 1.734-1.202.537-2.103 1.132-2.786 1.737-.073-1.436 1.132-2.877 3.603-3.952 4.657-2.025 12.116-2.025 16.778 0 2.477 1.076 3.684 2.52 3.613 3.957-.686-.607-1.589-1.203-2.797-1.742C22.695 11.616 19.425 11 15.993 11zm8.395 2.56c4.718 2.109 4.87 5.59.22 7.777-4.714 2.218-12.462 2.218-17.182 0-4.654-2.187-4.512-5.668.203-7.777 4.65-2.081 12.103-2.081 16.759 0zm-1.93 6.684c3.488-.994 3.375-2.576-.164-3.535-3.492-.945-9.081-.945-12.57 0-3.536.959-3.643 2.54-.152 3.535 3.54 1.008 9.35 1.008 12.887 0z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  )
}
