export function RoutinesLeavingHome(props) {
  return (
    <svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' className="hue-icon" {...props}>
      <path
        d='M31.708 21.29a.999.999 0 010 1.414l-3.997 3.997a.997.997 0 01-1.414 0 .999.999 0 010-1.414l2.29-2.29L16 23a1 1 0 110-2l12.586-.003-2.29-2.29a.999.999 0 111.414-1.414l3.997 3.997zM13 22c0 1.654 1.318 3 2.973 3H21v2a1 1 0 01-1 1H4a1 1 0 01-1-1l.014-11h-1.97C.096 16-.36 14.832.34 14.188l10.94-9.913a1.043 1.043 0 011.415.003l10.944 9.913c.755.81.239 1.81-.71 1.81h-1.913L21 19h-5.027C14.319 19 13 20.345 13 22z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  )
}
