export function RoomsKidsbedroom(props) {
  return (
    <svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' className="hue-icon" {...props}>
      <path
        d='M21 25.5c-1.104 0-2-1.119-2-2.5s.896-2.5 2-2.5 2 1.119 2 2.5-.896 2.5-2 2.5M16 15c-1.657 0-3-1.119-3-2.5s1.343-2.5 3-2.5 3 1.119 3 2.5-1.343 2.5-3 2.5m-5 10.5c-1.104 0-2-1.119-2-2.5s.896-2.5 2-2.5 2 1.119 2 2.5-.896 2.5-2 2.5zM16 11a1 1 0 110 2 1 1 0 110-2zm9 5a2.97 2.97 0 00-1.208.258c-.642-.804-1.514-1.497-2.553-2.036a6.428 6.428 0 001.215-3.761c0-.025-.004-.05-.004-.075A3.23 3.23 0 0021.77 4c-.918 0-1.743.387-2.331 1.002a6.427 6.427 0 00-6.882-.005A3.217 3.217 0 0010.23 4 3.23 3.23 0 007 7.23a3.23 3.23 0 002.536 3.152c0 .026-.004.053-.004.079 0 1.407.455 2.705 1.219 3.767-1.034.538-1.903 1.229-2.543 2.03A2.97 2.97 0 007 16a3 3 0 100 6c.028 0 .054-.008.081-.008A6.263 6.263 0 007 23c0 2.762 1.791 5 4 5 1.351 0 2.539-.842 3.264-2.123.562.079 1.141.123 1.736.123s1.174-.044 1.736-.123C18.461 27.158 19.649 28 21 28c2.209 0 4-2.238 4-5 0-.346-.028-.683-.081-1.008.027 0 .053.008.081.008a3 3 0 100-6'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  )
}
