export function BulbGeneralGroup(props) {
  return (
    <svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' className="hue-icon" {...props}>
      <path
        d='M20 25a6.96 6.96 0 01-4.497-1.644C19.295 21.936 22 18.288 22 14c0-.977-.146-1.918-.407-2.811C24.687 11.913 27 14.688 27 18c0 3.859-3.141 7-7 7M5 14c0-3.859 3.141-7 7-7a6.96 6.96 0 014.497 1.644C12.705 10.064 10 13.712 10 18c0 .977.146 1.918.407 2.811C7.313 20.087 5 17.312 5 14m14 0c0 3.311-2.313 6.086-5.406 6.81A6.939 6.939 0 0113 18c0-3.311 2.313-6.086 5.406-6.81.38.861.594 1.81.594 2.81m1-6l-.013.001C18.162 5.575 15.269 4 12 4 6.478 4 2 8.478 2 14s4.478 10 10 10l.013-.001C13.838 26.425 16.731 28 20 28c5.522 0 10-4.478 10-10S25.522 8 20 8'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  )
}
