export function RoomsOutdoorSocialtime(props) {
  return (
    <svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' className="hue-icon" {...props}>
      <path
        d='M12.29 22l.526-1.568a9.225 9.225 0 003.157.568 9.237 9.237 0 003.697-.773L20.265 22H12.29zM27 12a1 1 0 110 2h-1.688c-.766 2.15-2.132 3.969-3.873 5.214l1.61 4.791A1.997 1.997 0 0123 28a2 2 0 01-2-2c0-.364.104-.701.274-.995L20.936 24h-9.318l-1.114 3.318a1.002 1.002 0 01-1.268.629 1 1 0 01-.629-1.265l2.398-7.139C9.028 18.302 7.472 16.352 6.634 14H5a1 1 0 110-2h1.117a11.732 11.732 0 01-.12-.889 1.011 1.011 0 011-1.111h17.952c.596 0 1.054.519 1 1.111-.027.301-.071.596-.12.889H27zM12.723 7.916C12.649 7.867 12 7.414 12 6.723c0-.301.125-.741.723-1.139.203-.137.277-.25.277-.307 0-.105-.164-.283-.277-.361a.5.5 0 11.555-.832c.074.049.722.502.722 1.193 0 .299-.125.741-.722 1.139-.205.135-.278.25-.278.305-.002.105.162.283.28.365a.5.5 0 01-.557.83zm3 0C15.649 7.867 15 7.414 15 6.723c0-.3.125-.741.723-1.139.203-.136.277-.25.277-.306 0-.105-.164-.283-.277-.362A.499.499 0 0116 4a.5.5 0 01.278.084c.074.049.722.502.722 1.193 0 .3-.125.741-.722 1.139-.205.136-.278.25-.278.306-.002.105.162.283.28.364a.499.499 0 01-.557.83zm3 0C18.649 7.867 18 7.414 18 6.723c0-.301.125-.741.723-1.139.203-.137.277-.25.277-.307 0-.105-.164-.283-.277-.361a.5.5 0 11.555-.832c.074.049.722.502.722 1.193 0 .299-.125.741-.722 1.139-.205.135-.278.25-.278.305-.002.105.162.283.28.365a.5.5 0 01-.557.83z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  )
}
