export function HeroesLightstrip(props) {
  return (
    <svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' className="hue-icon" {...props}>
      <path
        d='M8.44 16.668a1.001 1.001 0 01.423 1.955 1 1 0 11-.423-1.955m4.888-1.057a1 1 0 11.42 1.957 1 1 0 01-.42-1.957m4.886-1.056a1 1 0 11.424 1.953 1 1 0 01-.425-1.953m4.887-1.057a1.001 1.001 0 11.422 1.953 1.001 1.001 0 01-.421-1.953M10.67 20.639l14.804-3.195a1.999 1.999 0 001.532-2.377 4.785 4.785 0 00-5.686-3.668L6.517 14.594a2 2 0 00-1.534 2.377 4.785 4.785 0 005.686 3.668M25 26a1 1 0 110-2 1 1 0 110 2m-5 0a1 1 0 110-2 1 1 0 110 2m-5 0a1 1 0 110-2 1 1 0 110 2m-5 0a1 1 0 110-2 1 1 0 110 2m17-4H9c-4 0-5-3-5-4v5a5 5 0 005 5h18a1 1 0 001-1v-4a1 1 0 00-1-1M22 8a1 1 0 110-2 1 1 0 110 2m-5 0a1 1 0 110-2 1 1 0 110 2m-5 0a1 1 0 110-2 1 1 0 110 2M7 8a1 1 0 110-2 1 1 0 110 2m16-4H5a1 1 0 00-1 1v4a1 1 0 001 1h18c4 0 5 3 5 4V9a5 5 0 00-5-5'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  )
}
