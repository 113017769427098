export function PresetsDimmerDimdown(props) {
  return (
    <svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' className="hue-icon" {...props}>
      <path
        d='M16.004 19.34v-6.666a3.337 3.337 0 013.334 3.333 3.338 3.338 0 01-3.334 3.334m0-8.334a5 5 0 11-.001 10.001 5 5 0 010-10.001zm0-2.007c-.55 0-1-.449-1-1 0-.55.45-1 1-1s1 .45 1 1c0 .551-.45 1-1 1zM24 15.014c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-8 8c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-8-8c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm2-6c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm12 2c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm0 10c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-12 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  )
}
