export function RoutinesDaytime(props) {
  return (
    <svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' className="hue-icon" {...props}>
      <path
        d='M16.013 11a5 5 0 015 5 5 5 0 01-5 5 5 5 0 01-5-5c0-1.58.747-2.971 1.891-3.888A4.955 4.955 0 0116.013 11zm0-2c-.55 0-1-.45-1-1V5c0-.55.45-1 1-1s1 .45 1 1v3c0 .55-.45 1-1 1zm0 14c.55 0 1 .45 1 1v3.013c0 .549-.45 1-1 1s-1-.451-1-1V24c0-.55.45-1 1-1zm-7-7c0 .55-.45 1-1 1H5c-.549 0-1-.45-1-1s.451-1 1-1h3.013c.55 0 1 .45 1 1zm18-1c.55 0 1 .45 1 1s-.45 1-1 1h-3c-.55 0-1-.45-1-1s.45-1 1-1h3zm-17.33 5.916a1 1 0 011.414 1.414l-2.13 2.13a1 1 0 01-1.414-1.414l2.13-2.13zm12.629-9.871a1.003 1.003 0 01-1.414 0 1.003 1.003 0 010-1.414l2.12-2.122a1 1 0 011.414 1.414l-2.12 2.122zm-12.644.06l-2.13-2.132a1.003 1.003 0 010-1.414 1.003 1.003 0 011.413 0l2.131 2.131a1.003 1.003 0 010 1.415 1.003 1.003 0 01-1.414 0zm12.7 9.8l2.12 2.121a1 1 0 01-1.413 1.414l-2.122-2.12a1.004 1.004 0 010-1.416 1.003 1.003 0 011.415 0z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  )
}
