export function UicontrolsNoMotion(props) {
  return (
    <svg {...props} viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' className={`hue-icon ${props.className}`}>
      <path
        d='M12.235 9.04c-1.104 0-2-1.371-2-2.662s.896-2.338 2-2.338c1.105 0 2 1.047 2 2.338 0 1.291-.895 2.662-2 2.662zm6.968 5.07a1 1 0 11-.484 1.938l-4-1a1 1 0 01-.635-.489l-.398-.725-.877 4.217c-.005.03-.025.052-.034.08l2.172 2.207a1 1 0 01.287.702v6a1 1 0 11-2 0v-5.59l-5.01-4.39a2.072 2.072 0 01-.084-1.119l.814-3.912-1.874.75-1.11 3.503a1 1 0 11-1.94-.485l1.235-4a.999.999 0 01.598-.686l1.992-.797a4.014 4.014 0 011.481-.286l4.018-.006a.981.981 0 01.796.498l1.478 2.694 3.575.895zM7.357 21.536l.996-2.579 1.616 1.416-.8 2.023-.156.272-3.231 4.04a1.001 1.001 0 01-1.563-1.25l3.138-3.922zM27.77 10.646a.999.999 0 11-1.414 1.413l-2.481-2.48-2.48 2.48a.997.997 0 01-1.414 0 .999.999 0 010-1.413l2.48-2.48-2.457-2.459a1 1 0 011.414-1.415l2.457 2.458 2.458-2.457a1.002 1.002 0 011.415 0c.39.39.39 1.023 0 1.414L25.29 8.165l2.48 2.48z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  )
}
