export function ArchetypesPendantLong(props) {
  return (
    <svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' className="hue-icon" {...props}>
      <path
        d='M28.245 16.159l-19.867 6.67a.995.995 0 01-.668-.013l-3.924-1.482a.303.303 0 01.011-.57l19.867-6.669a.986.986 0 01.667.012l3.925 1.482c.264.1.258.479-.011.57m3.106-5.443l-7.02-2.652a.986.986 0 00-.667-.012L17 10.289v-5.82C17 4.21 16.553 4 16 4c-.553 0-1 .21-1 .469v6.492L.683 15.766a1.008 1.008 0 00-.683.956v4.62c0 .42.259.795.648.942l7.021 2.651a.992.992 0 00.667.013l22.98-7.715c.408-.137.684-.522.684-.956v-4.619c0-.42-.259-.795-.649-.942'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  )
}
