export function ArchetypesBollard(props) {
  return (
    <svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' {...props} className="hue-icon">
      <path
        d='M21 12.5c0 .83-2.24 1.5-5 1.5s-5-.67-5-1.5V8.296c0-.243.265-.375.47-.244.798.51 2.527.948 4.53.948s3.732-.438 4.53-.948c.205-.131.47.001.47.244V12.5zm-7.658-7.222c1.6-.371 4.056-.371 5.541 0 1.517.38 1.496 1.013-.11 1.414-1.64.411-4.25.411-5.762 0-1.481-.401-1.305-1.034.331-1.414zm6.988-.798c-1.11-.32-2.59-.48-4.1-.48-1.5 0-3.02.16-4.22.48-1.321.35-2.01.83-2.01 1.31v20.56c0 .4.5.8 1.52 1.12 1.14.35 2.68.53 4.25.53 1.57 0 3.16-.18 4.39-.53 1.23-.35 1.84-.81 1.84-1.26V5.65c0-.43-.561-.85-1.67-1.17z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  )
}
