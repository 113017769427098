export function ArchetypesCeilingRound(props) {
  return (
    <svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' {...props} className="hue-icon">
      <path
        d='M23.163 20.244c-3.93 1.008-10.385 1.008-14.318 0-3.879-.994-3.76-2.576.169-3.535 3.875-.945 10.086-.945 13.965 0 3.933.959 4.059 2.54.184 3.535m4.762-8.242c-.292-.75-1.485-1.47-3.547-2.04-4.658-1.282-12.113-1.282-16.763 0-2.062.57-3.248 1.29-3.54 2.04a.944.944 0 00-.075.374v4.25c0 .127.022 3.255.075 3.375.285.72 1.388 1.418 3.337 1.98 4.718 1.358 12.466 1.358 17.183 0 1.942-.563 3.045-1.26 3.33-1.98a.94.94 0 00.075-.375v-7.25a.962.962 0 00-.075-.374'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  )
}
