export function RoutinesSunrise(props) {
  return (
    <svg {...props} viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' className={`hue-icon ${props.className}`}>
      <path
        d='M16.675 20.293l2.995 2.995a.999.999 0 11-1.414 1.414l-1.284-1.284.002 3.582a1 1 0 11-2 0l-.002-3.59-1.292 1.292a.999.999 0 11-1.414-1.414l2.995-2.995a.999.999 0 011.414 0zM31 17c.55 0 1 .45 1 1s-.45 1-1 1h-4c-.55 0-1-.45-1-1s.45-1 1-1h4zM5 17c.55 0 1 .45 1 1s-.45 1-1 1H1c-.55 0-1-.45-1-1s.45-1 1-1h4zm11-7c-.55 0-1-.45-1-1V5c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1zm7.706 2.534a1 1 0 01-1.414-1.414l2.828-2.828a1 1 0 011.414 1.414l-2.828 2.828zm-15.586 0L5.292 9.706a1 1 0 011.414-1.414l2.828 2.828a1.003 1.003 0 010 1.414 1.003 1.003 0 01-1.414 0zM15.963 12c4.97 0 9 4.408 9 10h-4.12l-3.378-3.377a2.123 2.123 0 00-3.004 0L11.083 22h-4.12c0-5.592 4.029-10 9-10z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  )
}
