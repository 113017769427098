export function RoomsDriveway(props) {
  return (
    <svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' className="hue-icon" {...props}>
      <path
        d='M24 20a2 2 0 11.001-4.001A2 2 0 0124 20zm-2.23-7H10.23c-.81 0-1.399-.752-1.186-1.515l1.002-3.599A1.225 1.225 0 0111.232 7h9.536c.554 0 1.041.363 1.186.886l1.002 3.599c.213.763-.376 1.515-1.186 1.515zM20 18.594A1.42 1.42 0 0118.577 20h-5.154A1.42 1.42 0 0112 18.594v-.188A1.42 1.42 0 0113.423 17h5.154A1.42 1.42 0 0120 18.406v.188zM8 20a2 2 0 11.001-4.001A2 2 0 018 20zm19.313-5.682l-1.205-.575a1.214 1.214 0 01-.649-.794L23.661 5.91A1.202 1.202 0 0022.499 5H9.501c-.548 0-1.025.374-1.162.91l-1.798 7.039c-.09.349-.327.64-.649.794l-1.204.575c-.42.201-.688.628-.688 1.098v6.37C4 22.456 4.537 23 5.2 23H7v2.174A1.83 1.83 0 008.826 27h1.348A1.83 1.83 0 0012 25.174V23h8v2.174A1.83 1.83 0 0021.826 27h1.348A1.83 1.83 0 0025 25.174V23h1.8c.663 0 1.2-.544 1.2-1.214v-6.37c0-.47-.268-.897-.687-1.098z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  )
}
