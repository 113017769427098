export function ArchetypesFloorSpot(props) {
  return (
    <svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' className="hue-icon" {...props}>
      <path
        d='M21.749 14.287c-1.193.42-2.794-1.03-3.574-3.24-.78-2.211-.445-4.345.75-4.766 1.193-.422 2.794 1.028 3.574 3.239.78 2.21.444 4.345-.75 4.767m2.623-5.431c-1.166-3.24-3.558-5.366-5.343-4.75L7.154 9.63c-1.354.527-1.544 3.2-.422 5.973 1.12 2.773 3.429 4.749 4.784 4.222l1.488-.546v3.746c-2.927.114-5.278.708-5.86 1.477a.61.61 0 00-.14.375v1.25C7.005 27.16 10.14 28 14.004 28 17.868 28 21 27.16 21 26.126v-1.25c0-.943-2.608-1.724-5.998-1.854v-4.415l8.248-2.765c1.785-.618 2.286-3.746 1.12-6.986'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  )
}
