export function ArchetypesSingleSpot(props) {
  return (
    <svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' className="hue-icon" {...props}>
      <path
        d='M25.512 22.445c-.775 2.226-2.367 3.686-3.555 3.26-1.187-.423-1.521-2.572-.745-4.796.776-2.226 2.367-3.686 3.555-3.262 1.187.425 1.521 2.572.745 4.798m.748-6.362L15 12.26v-1.885c2.255-.064 4.45-.342 6.162-.835 1.62-.47 2.538-1.05 2.776-1.65A.77.77 0 0024 7.579v-.764a.786.786 0 00-.062-.312c-.244-.625-1.238-1.226-2.956-1.701-3.882-1.07-10.094-1.07-13.969 0-1.719.475-2.706 1.076-2.95 1.7A.787.787 0 004 6.816v.764a.77.77 0 00.063.312c.237.6 1.156 1.18 2.781 1.65 1.712.493 3.903.77 6.156.834v1.906c-1.188.605-2.508 2.142-3.272 4.055-1.115 2.79-.928 5.482.42 6.013l11.914 5.544c1.774.622 4.153-1.517 5.313-4.78 1.159-3.261.66-6.408-1.115-7.03'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  )
}
