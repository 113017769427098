export function ArchetypesWallShade(props) {
  return (
    <svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' className="hue-icon" {...props}>
      <path
        d='M21.865 7.694c-1.572.407-4.154.407-5.728 0-1.55-.4-1.503-1.04.068-1.427 1.551-.382 4.034-.382 5.587 0 1.573.387 1.623 1.026.073 1.427m6.086 7.058l-2.699-9.089-.033-.094c-.25-.57-1.476-1.113-3.667-1.404a19.08 19.08 0 00-5.019.001c-2.188.29-3.41.832-3.658 1.403l-.049.147-2.774 9.032a.881.881 0 00-.045.397c.223 1.474 2.896 2.663 7.78 2.832C17.127 20.295 14.996 22 12.47 22a5.508 5.508 0 01-3.488-1.244c-.039-1.432-.183-2.823-.449-3.912-.258-1.052-.577-1.649-.907-1.803a.366.366 0 00-.172-.04H5.548a.372.372 0 00-.172.04c-.344.158-.674.803-.935 1.921-.588 2.523-.588 6.561 0 9.08.261 1.117.591 1.758.935 1.917a.372.372 0 00.172.041h1.906a.366.366 0 00.172-.04c.33-.156.65-.752.907-1.809.211-.863.344-1.92.412-3.032A7.486 7.486 0 0012.47 24c3.632 0 6.672-2.585 7.376-6.012 5.123-.12 7.924-1.336 8.147-2.848a.915.915 0 00-.042-.388'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  )
}
