export function RoomsCarport(props) {
  return (
    <svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' className="hue-icon" {...props}>
      <path
        d='M25.427 17.648c.35.165.573.517.573.903v5.414a.999.999 0 01-1 .998h-1v1.525c0 .824-.675 1.498-1.5 1.498h-1c-.825 0-1.5-.674-1.5-1.498v-1.525h-8v1.498c0 .823-.675 1.497-1.5 1.497h-1c-.825 0-1.5-.674-1.5-1.497v-1.498H7c-.553 0-1-.447-1-.998v-5.414c0-.386.223-.738.573-.903l1.003-.473a.993.993 0 00.541-.653l1.498-5.788a1 1 0 01.969-.748h10.832a1 1 0 01.969.748l1.498 5.788a.993.993 0 00.541.653l1.003.473zM22.5 22a1.499 1.499 0 100-2.995 1.499 1.499 0 100 2.995zM18 22c.55 0 1-.449 1-.998 0-.55-.45-.999-1-.999h-4c-.55 0-1 .449-1 .999 0 .549.45.998 1 .998h4zm-8.5-3a1.499 1.499 0 100 2.995A1.499 1.499 0 109.5 19zm.537-3.268c-.182.635.323 1.26 1.018 1.26h9.89c.695 0 1.2-.625 1.018-1.26l-.859-2.996c-.125-.434-.541-.736-1.017-.736h-8.174c-.476 0-.892.302-1.017.736l-.859 2.996zM31 4a1 1 0 011 1v22a1 1 0 11-2 0V9a1 1 0 00-1-1H3a1 1 0 00-1 1v18a1 1 0 11-2 0V5a1 1 0 011-1h30z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  )
}
