export function RoomsAttic(props) {
  return (
    <svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' className="hue-icon" {...props}>
      <path
        d='M16.561 10.368l8.753 12.063c.496.681.025 1.656-.8 1.656H7.477c-.827 0-1.297-.975-.801-1.657l8.763-12.063a.688.688 0 011.122.001zM16 22.078a3 3 0 100-6 3 3 0 000 6zm15.794 2.373c.5.666.03 1.627-.797 1.627h-.043a.999.999 0 01-.8-.402L28.93 24.03h.017l-2.28-3.037L17.46 8.733l-1-1.33a.578.578 0 00-.921 0l-1 1.33-9.206 12.261-2.28 3.037h.017l-1.225 1.645a.999.999 0 01-.8.402h-.042c-.828 0-1.298-.96-.797-1.627l1.249-1.665 2.28-3.036L12.943 7.49l1.459-1.944.898-1.196a.876.876 0 011.4 0l.898 1.196 1.459 1.944 2.939 3.913V9.11c0-.57.448-1.031 1-1.031H25c.553 0 1 .461 1 1.03v7.626l2.266 3.015 2.28 3.037 1.249 1.664z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  )
}
