export function ArchetypesDoubleSpot(props) {
  return (
    <svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' {...props} className="hue-icon">
      <path
        d='M30.004 23.529c-.651 1.804-1.99 2.987-2.986 2.644-.998-.345-1.28-2.085-.627-3.89.652-1.804 1.989-2.986 2.987-2.643.997.344 1.278 2.085.626 3.889M6.83 21.075c-.636.221-1.49-.54-1.906-1.699-.417-1.159-.24-2.279.397-2.499.636-.221 1.49.541 1.907 1.7.417 1.159.239 2.277-.398 2.498m23.704-2.759L21 15.194V9.758c1.305-.132 2.529-.315 3.595-.555 1.942-.441 3.045-.987 3.33-1.55A.587.587 0 0028 7.36v-.718a.606.606 0 00-.075-.293c-.292-.587-1.484-1.15-3.547-1.596-4.658-1.004-12.113-1.004-16.763 0-2.062.446-3.248 1.009-3.54 1.596A.617.617 0 004 6.642v.718c0 .1.023.199.075.293.285.563 1.388 1.109 3.337 1.55 1.064.239 2.286.422 3.588.554v3.476l-6.901 2.318c-1.121.388-1.436 2.356-.704 4.394.732 2.039 2.235 3.376 3.356 2.987l7.524-3.465c.85-.332.97-2.014.265-3.758-.371-.92-.951-1.692-1.54-2.176v-3.62c1.968.111 4.032.112 6 .001v5.535c-.895.588-1.821 1.707-2.387 3.073-.94 2.267-.781 4.454.354 4.886l10.032 4.505c1.495.505 3.498-1.234 4.475-3.884.976-2.649.555-5.207-.94-5.713'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  )
}
