export function RoomsDining(props) {
  return (
    <svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' className="hue-icon" {...props}>
      <path
        d='M24 3c1.1 0 2 .9 2 2v22c0 1.1-.9 2-2 2s-2-.9-2-2V16.251c-1.601-1.15-3-3.514-3-6.25 0-3.764 2.646-6.825 4.824-6.983.059-.005.115-.018.176-.018zM14 17.791V27a2 2 0 01-4 0v-9.209c0-.508-.193-.997-.541-1.368l-.295-.315A7.996 7.996 0 017 10.637V4c0-.55.45-1 1-1s1 .45 1 1v5c0 .55.45 1 1 1s1-.45 1-1V4c0-.55.45-1 1-1s1 .45 1 1v5c0 .55.45 1 1 1s1-.45 1-1V4c0-.55.45-1 1-1s1 .45 1 1V10.637a7.992 7.992 0 01-2.164 5.47l-.295.316A1.997 1.997 0 0014 17.791z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  )
}
