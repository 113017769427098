export function ArchetypesTableShade(props) {
  return (
    <svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' className="hue-icon" {...props}>
      <path
        d='M18.873 7.688c-1.571.403-4.154.403-5.728 0-1.551-.397-1.503-1.03.069-1.414 1.55-.378 4.034-.378 5.586 0 1.573.384 1.623 1.017.073 1.414m6.078 7.246l-2.699-9.243-.033-.096c-.251-.58-1.476-1.132-3.667-1.428a18.869 18.869 0 00-5.019.001c-2.187.295-3.41.846-3.658 1.427l-.048.151-2.775 9.184a.926.926 0 00-.045.404c.226 1.521 2.974 2.747 8 2.89v4.803c-2.446.13-4.396.718-4.884 1.473a.671.671 0 00-.12.375v1.25c0 1.035 2.688 1.875 6.005 1.875 3.316 0 6.006-.84 6.006-1.875v-1.25c0-.929-2.166-1.698-5.005-1.847v-4.805c5.021-.144 7.763-1.372 7.984-2.894a.932.932 0 00-.042-.395'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  )
}
