export function RoutinesComingHome(props) {
  return (
    <svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' className="hue-icon" {...props}>
      <path
        d='M31.014 21a1 1 0 110 2l-12.586.003 2.293 2.29a.999.999 0 11-1.414 1.414l-4-3.997a.999.999 0 010-1.414l4-4.003a.997.997 0 011.414 0 .999.999 0 010 1.414l-2.293 2.296h2.607l9.98-.003zm-18.04.997c0 .801.311 1.555.88 2.121L17.74 28H4.015a1 1 0 01-1-1l.013-11H1.046C.096 16-.36 14.832.34 14.188l10.952-9.913a1.045 1.045 0 011.417.003l10.955 9.913c.697.645.241 1.81-.71 1.81h-.746c-.036-.04-.066-.084-.104-.122A2.985 2.985 0 0019.98 15c-.803 0-1.557.312-2.124.88l-4.002 3.996a2.975 2.975 0 00-.88 2.121z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  )
}
