export function RoomsBedroom(props) {
  return (
    <svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' className="hue-icon" {...props}>
      <path
        d='M26 16H6a4 4 0 00-4 4v2a1 1 0 001 1h1v1a2 2 0 004 0v-1h16v1a2 2 0 004 0v-1h1a1 1 0 001-1v-2a4 4 0 00-4-4M9 10h5a1 1 0 110 2H9a1 1 0 110-2m9 0h5a1 1 0 110 2h-5a1 1 0 110-2M7.009 14h17.982c.558 0 1.009-.451 1.009-1.009V8a2 2 0 00-2-2H8a2 2 0 00-2 2v4.991C6 13.549 6.451 14 7.009 14'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  )
}
