export function PresetsDimmerDimup(props) {
  return (
    <svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' className="hue-icon" {...props}>
      <path
        d='M16.013 19.333v-6.666A3.337 3.337 0 0119.346 16a3.337 3.337 0 01-3.333 3.333m0-8.333a5 5 0 015 5 5 5 0 01-5 5 5 5 0 01-5-5c0-1.579.747-2.971 1.891-3.887A4.948 4.948 0 0116.013 11zm0 12c.55 0 1 .45 1 1v3.013c0 .549-.45 1-1 1s-1-.451-1-1V24c0-.55.45-1 1-1zm0-14c-.55 0-1-.45-1-1V5c0-.55.45-1 1-1s1 .45 1 1v3c0 .55-.45 1-1 1zm-7 7c0 .55-.45 1-1 1H5c-.549 0-1-.45-1-1s.451-1 1-1h3.013c.55 0 1 .45 1 1zm18-1c.55 0 1 .45 1 1s-.45 1-1 1h-3c-.55 0-1-.45-1-1s.45-1 1-1h3zm-17.33 5.916a1.003 1.003 0 011.414 0 1.003 1.003 0 010 1.414l-2.13 2.13c-.39.39-1.025.39-1.415 0a1.003 1.003 0 010-1.414l2.13-2.13zm12.629-9.871a1.003 1.003 0 01-1.414 0 1.003 1.003 0 010-1.414l2.12-2.121a1 1 0 011.415 1.414l-2.121 2.12zm-12.644.06l-2.13-2.132a1.003 1.003 0 010-1.414 1.003 1.003 0 011.413 0l2.131 2.131a1.003 1.003 0 010 1.415 1.003 1.003 0 01-1.414 0zm12.7 9.8l2.12 2.121a1.003 1.003 0 010 1.414 1.003 1.003 0 01-1.413 0l-2.122-2.12a1 1 0 011.415-1.415z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  )
}
