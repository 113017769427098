export function BulbGroup(props) {
  return (
    <svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' className={`hue-icon ${props.className}`}>
      <path
        d='M16.343 24.007a39.35 39.35 0 003.5.16c.949 0 2.285-.044 3.5-.16l-.584 3.625c-.03.255-.154.49-.35.669l-.545.495a1.032 1.032 0 00-.102.107l-.575.68a1.203 1.203 0 01-.917.417h-.853a1.2 1.2 0 01-.917-.416l-.575-.681a1.041 1.041 0 00-.103-.107l-.544-.495a1.075 1.075 0 01-.351-.67l-.584-3.624zm-9.114-2.132c.904.087 1.897.119 2.604.119.706 0 1.7-.032 2.603-.12L12 24.239a.81.81 0 01-.26.498l-.406.368a.834.834 0 00-.077.08l-.427.506a.89.89 0 01-.682.31h-.635a.89.89 0 01-.68-.31l-.429-.506a.976.976 0 00-.077-.08l-.406-.368a.798.798 0 01-.259-.498l-.433-2.363zM27.187 3.63c1.78 1.637.16 4.65-.384 5.726-1.465.426-3.809.81-6.966.81-3.155 0-5.5-.384-6.964-.81-.544-1.076-2.165-4.09-.385-5.726C14.39 1.88 19.518 2 19.838 2c.321 0 5.448-.12 7.35 1.63zm-7.35 7.702c1.778 0 4.369-.166 6.46-.645-1.584 4.922-1.855 9.425-1.801 10.707.012.295-.09.578-.284.802l-.515.589a32.96 32.96 0 01-3.86.214c-1.463 0-2.75-.083-3.858-.214l-.515-.59a1.143 1.143 0 01-.283-.801c.053-1.282-.217-5.785-1.801-10.707 2.09.479 4.682.645 6.458.645zm-7.153.497c-.813.104-1.765.17-2.854.17-2.254 0-3.93-.281-4.976-.596-.389-.79-1.545-3.004-.274-4.206C5.938 5.913 9.6 6 9.83 6c.063 0 .395-.006.863.012.122 1.497.863 2.853 1.232 3.687 0 0 .244.592.502 1.295.09.279.176.558.257.835zm.263.94c.236.891.431 1.76.592 2.586-.442 2.167-.535 3.915-.509 4.54a.788.788 0 01-.194.552l-.354.404A22.68 22.68 0 019.83 21c-1.006 0-1.891-.057-2.653-.147l-.353-.404a.789.789 0 01-.195-.552c.037-.88-.149-3.975-1.238-7.356 1.437.328 3.218.442 4.439.442.865 0 2.011-.062 3.117-.212z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  )
}
