export function ArchetypesFloorShade(props) {
  return (
    <svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' className="hue-icon" {...props}>
      <path
        d='M12.419 2.303c1.964-.403 5.192-.403 7.159 0 1.94.397 1.88 1.03-.085 1.413-1.937.378-5.043.378-6.982 0-1.967-.384-2.029-1.017-.092-1.413m8.473 10.128c1.203-.336 1.893-.762 2.064-1.204a.57.57 0 00.044-.223V1.994a.549.549 0 00-.044-.222c-.166-.425-.809-.837-1.946-1.17-2.752-.801-7.272-.801-10.024 0-1.132.333-1.776.744-1.942 1.17A.549.549 0 009 1.994v9.011a.57.57 0 00.044.223c.171.441.866.867 2.069 1.204 1.1.308 2.469.483 3.887.542v14.05c-2.927.114-5.279.709-5.86 1.477a.61.61 0 00-.14.375v1.25C9 31.16 12.135 32 16 32s7-.84 7-1.875v-1.25c0-.944-2.609-1.724-6-1.854V12.973c1.42-.057 2.791-.232 3.892-.54'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  )
}
