export function HeroesIris(props) {
  return (
    <svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' className="hue-icon" {...props}>
      <path
        d='M25.596 18.326c-1.535-.497-3.792-1.974-6.854-5.037-2.456-2.454-4.383-5.165-5.007-6.83-.135-.36.188-.727.565-.654.387.075.916.237 1.603.557 1.848.86 3.546 1.978 5.667 4.1 2.121 2.12 3.238 3.817 4.098 5.666.327.701.49 1.237.562 1.627.068.368-.278.686-.634.571m-3.558 4.184a.499.499 0 01-.629.321c-1.374-.444-3.085-1.557-5.026-3.253-2.297 1.26-6.052 2.75-5.955 3.391.186 1.227-.55 1.906-1.936.52-1.385-1.386-.707-2.12.522-1.935.644.097 2.141-3.707 3.406-5.999-1.509-1.796-2.703-3.6-3.186-4.89a.5.5 0 11.935-.353c.632 1.685 2.598 4.36 4.893 6.654 3.372 3.372 5.48 4.533 6.655 4.914a.5.5 0 01.321.63m5.21-6.779c-1.92-4.122-6.839-9.077-10.913-10.992-1.66-.78-2.9-.917-3.652-.532-.093.058-.184.123-.275.183a18.465 18.465 0 00-2.887 2.34c-5.64 5.63-7.868 12.534-2.304 18.09 5.491 5.482 12.481 3.33 18.12-2.3a18.277 18.277 0 002.312-2.835l.123-.183c.413-.76.28-2.044-.525-3.77'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  )
}
