export function RoomsGarage(props) {
  return (
    <svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' className="hue-icon" {...props}>
      <path
        d='M27.781 5.738c.293.293.292.801-.003 1.223l-4.504 6.449c-.4.575-1.162.741-1.562.342l-.939-.94-1.078 1.078-1.565-1.564 1.078-1.078-.939-.94c-.4-.4-.234-1.161.341-1.562l6.45-4.504c.421-.294.93-.295 1.223-.002l1.498 1.498zm-16.237 11.61l3.13 3.13-7.2 7.2a1.11 1.11 0 01-1.566 0l-1.564-1.566a1.109 1.109 0 010-1.565l7.2-7.2zm16.112 7.33a1.109 1.109 0 010 1.564l-1.413 1.414a1.108 1.108 0 01-1.566 0L11.181 14.16c-1.607.633-3.491.441-4.926-.574a5.26 5.26 0 01-2.15-5.368c.082-.402.593-.543.883-.252l2.039 2.04c.41.41 1.08.41 1.489 0l1.49-1.49c.41-.409.41-1.079 0-1.489L7.97 4.99c-.294-.294-.145-.807.262-.888a5.25 5.25 0 014.751 1.435 5.26 5.26 0 011.173 5.641l13.5 13.5z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  )
}
