export function RoutinesTimers(props) {
  return (
    <svg {...props} viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' className={`hue-icon ${props.className}`}>
      <path
        d='M20.512 13.502c1.067.923 1.684 2.247 1.893 3.625l1.084 7.728c.09.603-.386 1.145-1.01 1.145h-3.98a2 2 0 11-4 0h-3.977c-.625 0-1.104-.544-1.01-1.148l.988-7.742c.213-1.37.829-2.684 1.89-3.604A6.188 6.188 0 0116.453 12c1.557 0 2.978.567 4.059 1.502zm4.177-6.227a1.001 1.001 0 01-1.379 1.449A9.845 9.845 0 0016.5 6a9.846 9.846 0 00-6.81 2.724 1 1 0 11-1.38-1.449A11.843 11.843 0 0116.5 4c3.058 0 5.966 1.163 8.19 3.275zM12.2 11.713a.996.996 0 01-1.414-.013.999.999 0 01.014-1.414C12.282 8.832 14.36 8 16.5 8c2.231 0 4.261.816 5.714 2.3a1 1 0 11-1.428 1.4C19.712 10.604 18.189 10 16.5 10c-1.597 0-3.205.64-4.3 1.713z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  )
}
