export function ArchetypesPendantRound(props) {
  return (
    <svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' className="hue-icon" {...props}>
      <path
        d='M23.163 22.244c-3.93 1.008-10.385 1.008-14.318 0-3.879-.994-3.76-2.576.169-3.535 3.875-.945 10.086-.945 13.964 0 3.934.959 4.06 2.541.185 3.535m4.762-8.242c-.292-.75-1.485-1.47-3.547-2.04-2.07-.571-4.694-.886-7.378-.948V6.469C17 6.21 16.553 6 16 6c-.553 0-1 .21-1 .469v4.545c-2.688.062-5.315.376-7.385.948-2.062.57-3.248 1.29-3.54 2.04a.944.944 0 00-.075.375v7.25c0 .127.022.255.075.375.285.72 1.388 1.417 3.337 1.979 4.718 1.359 12.466 1.359 17.183 0 1.942-.562 3.045-1.259 3.33-1.979a.94.94 0 00.075-.375v-7.25a.962.962 0 00-.075-.375'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  )
}
