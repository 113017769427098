export function UicontrolsMotionSensitivity(props) {
  return (
    <svg {...props} viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' className={`hue-icon ${props.className}`}>
      <path
        d='M26.942 11.806c.545-.016 1.024.39 1.057.94a1 1 0 01-.94 1.056 8.391 8.391 0 01-6.419-2.44 8.388 8.388 0 01-2.44-6.419 1 1 0 011.996.117 6.391 6.391 0 001.858 4.888 6.365 6.365 0 004.888 1.858zm.021-1.213a5.755 5.755 0 01-3.867-1.687 5.76 5.76 0 01-1.688-3.868 1 1 0 01.962-1.036c.563-.04 1.017.41 1.036.962a3.766 3.766 0 001.104 2.527 3.763 3.763 0 002.527 1.104.999.999 0 01-.036 2c-.013 0-.025 0-.038-.002zm-1.41-4.141a4.048 4.048 0 01-.726-.988 1 1 0 111.774-.924 2.023 2.023 0 00.861.86 1 1 0 01-.924 1.775 4.058 4.058 0 01-.985-.723zm-13.318 2.55c-1.104 0-2-1.371-2-2.662s.896-2.338 2-2.338c1.105 0 2 1.047 2 2.338 0 1.29-.895 2.662-2 2.662zM7.357 21.499l.996-2.58 1.616 1.417-.8 2.023-.156.272-3.231 4.039a1.001 1.001 0 01-1.563-1.25l3.138-3.921zm11.846-7.428a1 1 0 11-.484 1.939l-4-1a1 1 0 01-.635-.489l-.398-.725-.877 4.217c-.005.03-.025.052-.034.08l2.172 2.207a1 1 0 01.287.702v6a1 1 0 11-2 0v-5.591l-5.01-4.389a2.072 2.072 0 01-.084-1.119l.814-3.912-1.874.749-1.11 3.504a1 1 0 11-1.94-.485l1.235-4.001a.999.999 0 01.598-.685l1.992-.797a4.014 4.014 0 011.481-.286l4.018-.006a.981.981 0 01.796.498l1.478 2.694 3.575.895z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  )
}
