export function ArchetypesWallSpot(props) {
  return (
    <svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' className="hue-icon" {...props}>
      <path
        d='M23.391 21.516c-1.907 1.391-4.06 1.68-4.81.646-.748-1.035.192-3 2.1-4.39 1.906-1.392 4.06-1.68 4.81-.648.748 1.036-.192 3.001-2.1 4.392m4.224-4.915L18.87 6.595c-.898-1.16-3.53-.572-5.88 1.31-2.352 1.883-3.595 4.685-2.699 5.844l.876 1.25H8.974c-.052-1.21-.197-2.37-.44-3.296-.259-.971-.579-1.523-.909-1.665A.396.396 0 007.453 10H5.547a.415.415 0 00-.172.038c-.343.146-.673.743-.935 1.774-.587 2.328-.587 6.055 0 8.38.262 1.031.592 1.624.935 1.77a.414.414 0 00.172.037h1.906a.395.395 0 00.172-.037c.33-.142.65-.694.908-1.669.244-.925.388-2.085.441-3.294h3.594l4.946 7.063c1.107 1.55 4.266 1.135 7.056-.925s4.152-4.987 3.045-6.536'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  )
}
